import {
  ArrowLeftIcon,
  Badge,
  Button,
  Heading,
  Link,
  Pane,
  SelectMenu,
  Text,
} from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContext from "../../contexts/Loading";
import UserContext from "../../contexts/User";
import {
  doc,
  getDocs,
  collection,
  query,
  limit,
  where,
  addDoc,
  orderBy,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import Card from "./Card";
import {
  fetchGet,
  fetchPost,
  getFileUrl,
  imageExists,
} from "../../utils/helpers";
import { isMobile } from "react-device-detect";
import { ReactComponent as CancelIcon } from "../../assets/cancel.svg";
import { ReactComponent as ShopIcon } from "../../assets/shop.svg";

const getMeta = (url, cb) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

const toUpperCaseIfExists = (str) => {
  if (str == null) {
    return str;
  }

  return str.toUpperCase();
};

function Product(props) {
  const [product, setProduct] = useState({});
  const [id, setId] = useState(null);
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const [generatedPreviewUrl, setGeneratedPreviewUrl] = useState(null);

  const [cart, setCart] = useState([]);
  useEffect(() => {
    if (user && user.isSetUp === false) {
      navigate("/setup");
    }
  }, [user]);

  const removeFromCart = () => {
    const itemIndex = cart.findIndex((item) => item.id === id);
    const newCart = cart.filter((item) => item.id !== id);
    localStorage.setItem("cart", JSON.stringify(newCart));
    if (newCart.length > 0) {
      const newIndex = Math.min(itemIndex, newCart.length - 1);
      setId(newCart[newIndex].id);
    } else {
      setId(null);
    }
    setCart(newCart);
  };

  const fetchProduct = async () => {
    const productsRef = collection(db, "catalog");
    const q = query(productsRef, where("id", "==", id, limit(1)));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      const data = querySnapshot.docs[0].data();
      console.log("data", data);

      getMeta(data.images[0], (err, img) => {
        if (img === undefined) {
          alert("Error fetching product images");
          navigate("/catalog");
        }
        const expectedHeight = window.innerHeight * 0.8;
        const ratio = expectedHeight / img.naturalHeight;
        setProduct({
          ...data,
          width: ratio * img.naturalWidth,
          height: expectedHeight,
        });
      });
    }
  };

  useEffect(() => {
    if (id === null) {
      let currentCart = localStorage.getItem("cart");
      currentCart = JSON.parse(currentCart);
      if (currentCart && currentCart.length > 0) {
        setId(currentCart[0].id);
        setCart(currentCart);
      }
    }
  }, []);

  const fetchGenerated = async () => {
    const reqRef = collection(db, "requests");
    const q = query(
      reqRef,
      where("product", "==", id),
      where("user", "==", user.uid),
      where("approval_state", "==", "approved")
    );
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((d) => d.data());
    const completedResult = results.filter(
      (result) => result.complete && result.transparent_output_url
    );
    console.log("completedResult", completedResult, user);
    if (completedResult.length > 0) {
      setGeneratedPreviewUrl(completedResult[0].transparent_output_url);
    } else {
      setGeneratedPreviewUrl(null);
    }
  };
  useEffect(() => {
    if (id && user) {
      fetchProduct();
      fetchGenerated();
    }
  }, [id, user]);

  console.log("product", product);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "white",
      }}
    >
      <div style={{ marginTop: 30 }}>
        <div
          style={{
            float: "left",
            top: 50,
            left: 20,
            position: "absolute",
            cursor: "pointer",
          }}
          onClick={() => navigate("/catalog")}
        >
          <ArrowLeftIcon />
        </div>
        <div style={{ width: "100vw", maxHeight: "100vh" }}>
          <div
            style={{
              marginTop: "10vh",
              width: "90%",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div style={{ width: "29%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 20,
                  alignSelf: "center",
                }}
              >
                {product.images &&
                  product.images.slice(0, 4).map((image, index) => {
                    return (
                      <img
                        key={image}
                        src={image}
                        style={{
                          borderRadius: "50%",
                          width: 100,
                          height: 100,
                          objectFit: "cover",
                        }}
                      />
                    );
                  })}
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                bottom: "20vh",
                marginLeft: "auto",
                marginRight: "auto",
                zIndex: 1000,
                left: 0,
                right: 0,
              }}
            >
              <Button
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  height: 40,
                  borderRadius: 10,
                  fontSize: 15,
                  opacity: 0.9,
                  marginRight: 20,
                }}
                onClick={removeFromCart}
              >
                {" "}
                <CancelIcon
                  style={{ height: 15, width: 15, marginRight: 5 }}
                />{" "}
                Remove Outfit
              </Button>
              <Button
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  height: 40,
                  borderRadius: 10,
                  fontSize: 15,
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                }}
                onClick={() => {
                  window.open(product.url, "_blank");
                }}
              >
                <ShopIcon style={{ height: 15, width: 15, marginRight: 5 }} />{" "}
                Shop on {toUpperCaseIfExists(product.brand)}
              </Button>
            </div>
            <div style={{ width: "29%", position: "relative" }}>
              {generatedPreviewUrl === null ? (
                <div style={{ height: "75vh", width: 200 }}>
                  Please browse our catalog and select items to try on.
                </div>
              ) : (
                <img
                  src={generatedPreviewUrl}
                  style={{
                    filter: "drop-shadow(rgba(34, 34, 34, 0.5) -3px 36px 79px)",
                    left: 0,
                    height: "75vh",
                  }}
                />
              )}
            </div>
            <div style={{ width: "29%", alignSelf: "center" }}>
              <Pane
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  fontFamily: "DM Sans",
                }}
              >
                <Text
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: 800,
                    textAlign: "left",
                    fontSize: 20,
                  }}
                >
                  {toUpperCaseIfExists(product.brand)}
                </Text>
                <Text
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    textAlign: "left",
                    fontSize: 20,
                  }}
                >
                  {product.name}
                </Text>

                <Text style={{ fontFamily: "DM Sans" }} size={500}>
                  {product.description}
                </Text>
                <br />
                 <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                {product.old_price && product.old_price !== product.current_price && 
                <Text style={{ fontFamily: "DM Sans" }} >
                  <strike>${product.old_price}</strike>
                  </Text>
                }
                {product.current_price && <Badge color={(product.old_price && product.old_price !== product.current_price) ? "red" : "neutral"} fontWeight={400} fontSize={20} height={25} justifyContent="center" alignItems="center" alignContent="center">
                <Text style={{ fontFamily: "DM Sans" }} size={500}>${product.current_price}
                  </Text>
                </Badge>}
                </div> 
              </Pane>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          borderTop: "1px black solid",
          width: "90vw",
          margin: "auto",
          marginTop: 40,
        }}
      ></div>
      <h3
        style={{
          fontFamily: "DM Sans",
          textDecoration: "underline",
          textAlign: "center",
        }}
      >
        FITTING ROOM
      </h3>
     
      <Pane
        style={{
          width: "90vw",
          margin: "0 auto",
          display: "flex",
          flexDirection: "row",
          alignContent: "space-evenly",
          flexFlow: "wrap",
          justifyContent: "center",
        }}
      >
         <small style={{fontFamily: "DM Sans", fontSize: 12, textAlign: "center", width: "100%", margin: "0 auto", marginTop: 10}}>
      Our technology gives a close approximation but can’t fully guarantee fit (yet!). We're constantly improving our accuracy and and hope you’ll enjoy experimenting with new looks, styles, and finding fresh inspiration.
      </small>
        {cart.map((item) => {
          return (
            <Pane
              elevation={0}
              float="left"
              backgroundColor="white"
              width={200}
              height={200}
              margin={24}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              borderRadius={20}
              cursor="pointer"
              onClick={() => setId(item.id)}
            >
              <img
                src={item.thumbnail}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 20,
                }}
              />
            </Pane>
          );
        })}
      </Pane>
    </div>
  );
}

export default Product;
