import { Link } from "react-router-dom";

function Footer() {
    const isMobile = window.innerWidth < 768;
  return (
    <div style={{ marginTop: 50, backgroundColor: "#F5F5F5", height: 100, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
        <div style={{fontFamily: "DM Sans", fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 20}}>Versipel©{new Date().getFullYear()}</div>
    <div style={{width: "100%", fontFamily: "DM Sans", display: "flex", justifyContent: "center", alignItems: "center", fontSize: 14, marginLeft: isMobile ? 0 : -100}}>
      <div style={{marginRight: 20}}><Link style={{textDecoration: "none", color: "black", fontFamily: "DM Sans"}} to="/privacy">Privacy Policy</Link></div>
      <div><Link style={{textDecoration: "none", color: "black", fontFamily: "DM Sans"}} to="/terms">Terms of Service</Link></div>
    </div>
    </div>
  )
}

export default Footer;
