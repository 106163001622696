import { Pane } from "evergreen-ui";
import { useContext, useEffect } from "react";
import UserContext from "../../contexts/User";
import { useNavigate } from "react-router-dom";

export default function Processing(props) {
    const { user } = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (user.uid && user.processed) {
            navigate("/catalog")
        }
    }, [user])
    return (<Pane style={{textAlign: "center", marginTop: "30vh", fontFamily: 'DM Sans'}}>Hang tight! Our AI models need a few hours — up to a day — for the initial setup. We'll email you as soon as we're ready, keep an eye on your inbox!</Pane>)
}