import { Button, Link, Menu, Popover, Position, Text } from "evergreen-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../utils/helpers";
import "./NavBar.css";
import {ReactComponent as HomeIcon} from "../assets/home.svg"
import {ReactComponent as FittingRoomIcon} from "../assets/fittingroom.svg"
import { useContext } from "react";
import UserContext from "../contexts/User";
import { analytics } from "../utils/firebase";
import { logEvent } from "firebase/analytics";


export default function NavBar(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const { user } = useContext(UserContext);
  console.log("location", location.pathname)
  const isMobile = window.innerWidth < 768;
  const iconSize = isMobile ? 50 : 70

  const handleLoginClick = () => {
    logEvent(analytics, "login_from_navbar_initiated");

    navigate("/login");
  };

  return (
    <nav
      style={{ height: 60, width: "95%", zIndex: 1000000, marginTop: 10, marginLeft: 'auto', marginRight: 'auto' }}
    >
      <ul>
        <li style={{cursor: 'pointer'}}>
          <div style={{fontSize: 30}} onClick={() => window.location.href = "/"}>VERSIPEL</div>
        </li>
       {props.authed && location.pathname !== "/setup" && location.pathname !== "/" && location.pathname !== "/processing" &&  <li style={{marginLeft: isMobile ? 0 : '35vw'}}>
      <HomeIcon style={{height: iconSize, width: iconSize, color: location.pathname === "/" || location.pathname === "/catalog" ? "black" : "#C1C1C1", cursor: "pointer"}} onClick={() => navigate("/catalog")}/>
      <FittingRoomIcon style={{height: iconSize, width: iconSize, marginBottom: -5, cursor: "pointer", color: location.pathname === "/fitting" ? "black" : "#C1C1C1",}} onClick={() => navigate("/fitting")} />
      </li>}
      {props.authed && isMobile && <>
      <Popover position={Position.BOTTOM_LEFT}
      
      content={<Menu>
        {user.isSetUp && <Menu.Item onClick={() => navigate("/setup")}>
                Setup</Menu.Item>}
        <Menu.Item  onClick={logout}>Logout</Menu.Item>
      </Menu>}
      ><Button style={{float: "right"}}>Menu</Button></Popover>
      </>}

        {props.authed && !isMobile && (
          <div style={{display: "flex", flexDirection: "row", gap: 10, float: "right"}}>
            <li >
            <div>
              <Button
                appearance="minimal"
                style={{ marginTop: 10 }}
                onClick={() => window.location.href = "mailto:support@versipel.com"}
                >
                Contact Us
              </Button>
            </div>
          </li>
          <li >
           {user.isSetUp && <div>
              <Button
                appearance="minimal"
                style={{ marginTop: 10 }}
                onClick={() => navigate("/setup")}
              >
                Setup
              </Button>
            </div>}
          </li>
          <li >
            <div>
              <Button
                appearance="minimal"
                style={{ marginTop: 10 }}
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          </li>
          </div>
         
         
        )}
        {!props.authed && (
          <li style={{ float: "right" }}>
            <div>
              <Button
                appearance="minimal"
                style={{ marginTop: 10 }}
                onClick={handleLoginClick}
              >
                Login
              </Button>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
}
