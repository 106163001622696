import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCPUHEgGjxwqwDy1geCckCSmwFl_h7QufY",
  authDomain: "versipel.app",
  projectId: "fitapp-5ed6e",
  storageBucket: "fitapp-5ed6e.appspot.com",
  messagingSenderId: "420834812676",
  appId: "1:420834812676:web:e25f94418924b629132651",
  measurementId: "G-6F6LBSHD06",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
