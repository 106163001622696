import { Button, CornerDialog, Heading, Pane, RadioGroup, SelectMenu, TextInput } from "evergreen-ui";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../contexts/Loading";
import UserContext from "../../contexts/User";
import {
  doc,
  getDocs,
  collection,
  query,
  limit,
  where,
  orderBy,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import Card from "./Card";
import { capitalizeFirstLetter } from "../../utils/helpers";
function Catalog(props) {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();

  const { user, authed } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [showPreference, setShowPreference] = useState(false);
  const [preferSex, setPreferSex] = useState();

  const fetchProducts = async () => {
    try {
      if (!user || Object.keys(user).length === 0) {
        console.log("User not defined, skipping fetchProducts.");
        return;
      }
    
      // Get all requests for this user where it's complete and approval_state = approved
      const requestsRef = collection(db, "requests");
      const existing_fits = query(
        requestsRef,
        where("user", "==", user.uid),
        where("complete", "==", true),
        where("approval_state", "==", "approved"),
        orderBy("submittedAt", "desc"),
        limit(100)
      );
  
      const existing_fits_snapshot = await getDocs(existing_fits);
      const existing_fits_data = existing_fits_snapshot.docs.map((doc) => doc.data());
      let existing_fits_product_ids = existing_fits_data.map((fit) => fit.product);
      existing_fits_product_ids = [...new Set(existing_fits_product_ids)];
      
      if (existing_fits_product_ids.length === 0) {
        console.log("No products found for approved fits.");
        setProducts([]);
        return;
      }
  
      console.log("existing_fits_product_ids", existing_fits_product_ids);
  
      // Get the products from these ids
      const productsRef = collection(db, "catalog");
      let products = [];
      
      // Split product IDs into chunks of 10 to avoid Firestore's 'in' query limit
      const chunkSize = 10;
      
      for (let i = 0; i < existing_fits_product_ids.length; i += chunkSize) {
        const chunk = existing_fits_product_ids.slice(i, i + chunkSize);
      
        const q = query(productsRef, where('id', 'in', chunk));
      
        const querySnapshot = await getDocs(q);
        const chunkProducts = querySnapshot.docs.map((doc) => doc.data());
      
        // Create a lookup map for chunkProducts
        const productMap = new Map(chunkProducts.map((product) => [product.id, product]));
      
        // Use the map to reorder products in O(n) time
        const orderedChunkProducts = chunk.map((id) => productMap.get(id));
      
        products = products.concat(orderedChunkProducts);
      }
      
      // Filter by 'show', 'category', and 'sex' preference after querying
      const filteredProducts = products.filter(doc => 
        doc.show === true && doc.available === true &&
        (doc.category === selectedCategory.toLowerCase() || selectedCategory.toLowerCase() == 'all') &&
        (doc.sex === preferSex || preferSex === "both")
      );
  
      setProducts(filteredProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
    useEffect(() => {
    fetchProducts();
  }, [user, selectedCategory, preferSex]);

  useEffect(() => {
    console.log("user", user);
    const setupRedirect = window.location.search.includes("setup=complete");
    if (user.uid && user.isSetUp !== true && !setupRedirect) {
      navigate("/setup");
    }
    else if (user.uid && !user.processed) {
      navigate("/processing")
    }
    
    if (user.isSetUp && (user.clothing_preference === "" || user.clothing_preference === null || user.clothing_preference === undefined)) {
      setShowPreference(true);
    }
    else if (!(user.clothing_preference === "" || user.clothing_preference === null || user.clothing_preference === undefined)) {
      setPreferSex(user.clothing_preference)
    }
  }, [user]);

  const updateUserClothPreference = () => {
    const newUser = user.originalDBData;
    newUser.clothing_preference = preferSex;
    user.clothing_preference = preferSex;
    updateDoc(doc(db, "users", user.uid), {...newUser});
    setShowPreference(false);

  }
  if (!user.processed) {
    return null
  }

  return (
    <div
      style={{
        height: "100%",
        width: "93%",
        margin: '0 auto',
        background: "white",
        overflow: "scroll",
      }}
    >
      <CornerDialog
        title="Clothing Preference"
        isShown={showPreference}
        onCloseComplete={() => setShowPreference(false)}
        confirmLabel="Submit"
        hasCancel={false}
        hasClose={false}
        onConfirm={updateUserClothPreference}
      >
        What type of clothes are you interested in?
        <RadioGroup
      label="Sex"
      size={16}
      value={preferSex}
      options={[{ label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Both', value: 'both' }]}
      onChange={event => setPreferSex(event.target.value)}
    />
      </CornerDialog>

     <h1 style={{textTransform: 'uppercase', fontSize: '6em', fontWeight: 100, marginBottom: 20}}>find your fit</h1>
     <hr/>
     <div style={{textTransform: 'uppercase', marginBottom :50, fontFamily: 'sans-serif'}}>find the style that suits your lifestyle</div>
      <div style={{ width: "100%", display: "inline-block" }}>
      {["All", "Shirt", "Sweatshirt"].map((label) => (<Button style={{
        width: 100,
        fontFamily: 'sans-serif',
        backgroundColor: '#f0f0f0',
        borderRadius: 5,
        marginRight: 5,
        border: 'none'

      }} onClick={() => setSelectedCategory(label)}>{label} +</Button>))}
      </div>
<br/>
<div></div>
<br/>
      <Pane
        style={{ width: '100%', margin: '0 auto', rowGap: 20 }}
        display="flex"
        flexDirection="column"
        flexFlow="wrap"
        justifyContent="space-around"
        
      >
        {products.map((product, index) => {
          console.log("product", product);
          return (<Card key={`${product.name}-${index}`} product={product} autoplay={false} disableIndicators={true}/>);
        })}
      </Pane>
    </div>
  );
}

export default Catalog;
